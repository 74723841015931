import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import PublishIcon from "@mui/icons-material/Publish";
import { Modal, TextField, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { ptBR } from "@mui/x-data-grid/locales";
import { AxiosError } from "axios";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import ContractIcon from "../../../../assets/Plataforma/contractIcon.svg";
import FolderIcon from "../../../../assets/Plataforma/folderIcon.svg";
import PourposeIcon from "../../../../assets/Plataforma/pourposeIcon.svg";
import CircleCheckbox from "../../../../components/CircleCheckBox";
import Datatable from "../../../../components/Datatable";
import StatusCard from "../../../../components/StatusCard";
import TotalCard from "../../../../components/TotalCard";
import { StatusContratoParceiroColorMap } from "../../../../enums/EnumStatusContratoParceiro.enum";
import {
  EnumStatusGDProposta,
  EnumStatusGDPropostaNumericMap,
  StatusPropostaColorMap,
} from "../../../../enums/EnumStatusGDProposta.enum";
import {
  DefaultUpdateGDProposta,
  IGDProposta,
  IGDPropostaPaged,
  IStatusDashboardProposta,
  IUpdateGDProposta,
} from "../../../../models/GDProposta";
import * as services from "../../../../services/api/PropostaService";
import * as D from "../../../../styles/appComponents";
import * as C from "./style";
import { useAuth } from "../../../../hooks/useAuth";
import SkeletonDefaultPage from "../../../../components/SkeletonLoads/DefaultPage";
import { useNavigate } from "react-router-dom";
import { moneyFormatter } from "../../../../utils/moneyFormatter";

const ParceiroPropostas: React.FC = () => {
  const { user } = useAuth();
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(true);
  const [nomeGDProposta, setNomeGDProposta] = useState<string | null>(null);
  const [celularGDProposta, setCelularGDProposta] = useState<string | null>(
    null
  );
  const [emailGDProposta, setEmailGDProposta] = useState<string | null>(null);
  const [parceiroGDProposta, setParceiroGDProposta] = useState<string | null>(
    null
  );
  const [backofficeGDProposta, setBackofficeGDProposta] = useState<
    string | null
  >(null);
  const [createdAtGDProposta, setCreatedAtGDProposta] = useState<string | null>(
    null
  );
  const [editar, setEditar] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [openModalSeeMore, setOpenModalSeeMore] = useState(false);
  const [openModalDocuments, setOpenModalDocuments] = useState(false);
  const [statusOpen, setStatusOpen] = useState(false);
  const [selectedId, setSelectedId] = React.useState<number>(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);

  const [statsDashboard, setStatsDashboard] = useState<
    IStatusDashboardProposta[]
  >([
    {
      status: EnumStatusGDProposta.naoTeveInteresse,
      quantidade: 0,
      totalkWh: 0,
      totalReais: 0,
    },
    {
      status: EnumStatusGDProposta.emAnalise,
      quantidade: 0,
      totalkWh: 0,
      totalReais: 0,
    },
    {
      status: EnumStatusGDProposta.aprovada,
      quantidade: 0,
      totalkWh: 0,
      totalReais: 0,
    },
    {
      status: EnumStatusGDProposta.reprovada,
      quantidade: 0,
      totalkWh: 0,
      totalReais: 0,
    },
    {
      status: EnumStatusGDProposta.cancelada,
      quantidade: 0,
      totalkWh: 0,
      totalReais: 0,
    },
  ]);

  const [GDProposta, setGDPropostaModal] = useState<IGDProposta>({
    id: "",
    idReferencial: "",
    createdAt: new Date(),
    updatedAt: new Date(),
    cliente: "",
    celular: "",
    status: EnumStatusGDProposta.aprovada,
    nomeCaptador: "",
    celularCaptador: "",
    email: "",
    nomeBackoffice: "",
  });

  const [rows, setRows] = useState<IGDPropostaPaged[]>([]);

  const getStats = () => {
    services
      .GetStats()
      .then(
        (response: {
          data: React.SetStateAction<IStatusDashboardProposta[]>;
        }) => {
          setStatsDashboard(response.data);
        }
      )
      .catch((e: AxiosError) => {
        var errorMessage: string = e.response
          ? String(e.response?.data)
          : "Houve um erro ao pegar os dados.";

        Swal.fire({
          position: "top-end",
          toast: true,
          icon: "warning",
          title: errorMessage,
          showConfirmButton: false,
          showCloseButton: true,
          timer: 3000,
        });
      });
  };

  const getPropostas = async () => {
    await getStats();

    services
      .GetPropostas(page, pageSize)
      .then((response) => {
        setRows(response.data.data);
        setTotalPages(response.data.totalPages);
        setLoading(false);
      })
      .catch((e: AxiosError) => {
        var errorMessage: string = e.response
          ? String(e.response?.data)
          : "Houve um erro ao pegar os dados.";

        Swal.fire({
          position: "top-end",
          toast: true,
          icon: "warning",
          title: errorMessage,
          showConfirmButton: false,
          showCloseButton: true,
          timer: 3000,
        });
      });
  };

  useEffect(() => {
    getPropostas();
  }, [page, pageSize, openModalSeeMore]);

  useEffect(() => {
    setNomeGDProposta(GDProposta.cliente);
    setCelularGDProposta(GDProposta.celular);
    setEmailGDProposta(GDProposta.email);
    setParceiroGDProposta(GDProposta.nomeCaptador);
    setBackofficeGDProposta(GDProposta.nomeBackoffice);
    setCreatedAtGDProposta(new Date(GDProposta.createdAt).toLocaleDateString());
    setSelectedId(Number(GDProposta.status));
  }, [GDProposta]);

  const handleChangePage = (value: number) => {
    setPage(value);
  };

  const handleChangePageSize = (value: number) => {
    setPageSize(value);
  };

  const handleChangeMobile = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {};

  const handleOpenModalSeeMore = async (id: string) => {
    await getGDPropostaModalData(id);
    setOpenModalSeeMore(true);
  };

  const getGDPropostaModalData = async (id: string) => {
    await services
      .GetPropostaById(id)
      .then((response) => {
        setGDPropostaModal(response.data);
      })
      .catch((e: AxiosError) => {
        var errorMessage: string = e.response
          ? String(e.response?.data)
          : "Houve um erro ao pegar os dados.";

        Swal.fire({
          position: "top-end",
          toast: true,
          icon: "warning",
          title: errorMessage,
          showConfirmButton: false,
          showCloseButton: true,
          timer: 3000,
        });
      });
  };

  const handleCloseModalSeeMore = () => {
    setOpenModalSeeMore(false);
  };

  const handleOpenModalDocuments = () => {
    setOpenModalDocuments(true);
  };

  const handleCloseModalDocuments = () => {
    setOpenModalDocuments(false);
  };

  const handleSelect = (id: number) => {
    if (editar) {
      setSelectedId(id);
    }
  };

  const handleUpdateGDProposta = async () => {
    var uploadGDPropostaData: IUpdateGDProposta = {
      ...DefaultUpdateGDProposta,
      id: GDProposta.id,
      nome: nomeGDProposta,
      celular: celularGDProposta,
      email: emailGDProposta,
      status: selectedId,
    };

    await services
      .UpdateGDProposta(uploadGDPropostaData)
      .then(() => {
        getGDPropostaModalData(GDProposta.id);
        setEditar(false);
        Swal.fire({
          position: "top-end",
          toast: true,
          icon: "success",
          title: "Editado com sucesso!",
          showConfirmButton: false,
          showCloseButton: true,
          timer: 3000,
        });
      })
      .catch((e: AxiosError) => {
        var errorMessage: string = e.response
          ? String(e.response?.data)
          : "Houve um erro ao pegar os dados.";

        Swal.fire({
          position: "top-end",
          toast: true,
          icon: "warning",
          title: errorMessage,
          showConfirmButton: false,
          showCloseButton: true,
          timer: 3000,
        });
      });
  };

  const handleStatusOpen = () => {
    setStatusOpen(!statusOpen);
  };

  const handleOpenViewProposta = (id: string, disabled: boolean): void => {
    if (disabled) return;
    window.open("/Parceiro/Documento/Proposta/" + id, "_blank");
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1024);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const columns: GridColDef[] = [
    {
      field: "cliente",
      headerName: "Cliente",
      flex: 1,
      align: "center",
      renderCell: (params) => (
        <D.GridField>
          <Typography
            style={{
              whiteSpace: "normal",
              fontSize: 14,
            }}
          >
            {params.value}
          </Typography>
        </D.GridField>
      ),
    },
    { field: "instalacao", headerName: "Instalação", flex: 1 },
    {
      field: "parceiro",
      headerName: "Parceiro",
      flex: 1,
      align: "center",
      renderCell: (params) => (
        <D.GridField>
          <Typography
            style={{
              whiteSpace: "normal",
              fontSize: 14,
            }}
          >
            {params.value}
          </Typography>
        </D.GridField>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <D.GridField $alignCenter>
          <D.Circle color={StatusPropostaColorMap[params.value]} />
        </D.GridField>
      ),
    },
    {
      field: "documentos",
      headerName: "Documentos",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <D.GridField $alignCenter>
          <D.GridImage
            src={FolderIcon}
            alt="folderIcon"
            $disable
            onClick={() => {}}
          />
        </D.GridField>
      ),
    },
    {
      field: "proposta",
      headerName: "Proposta",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <D.GridField $alignCenter>
            <D.GridImage
              $disable={!params.row.temProposta}
              onClick={(): void =>
                handleOpenViewProposta(params.row.id, !params.row.temProposta)
              }
              src={PourposeIcon}
              alt="pourposeIcon"
            />
          </D.GridField>
        );
      },
    },
    {
      field: "contratar",
      headerName: "Contratar",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <D.GridField $alignCenter>
          <D.GridImage $disable src={ContractIcon} alt="contractIcon" />
        </D.GridField>
      ),
    },
    {
      field: "outras",
      headerName: "Outras",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <D.GridField $alignCenter>
          <D.DefaultGridButton
            onClick={() => handleOpenModalSeeMore(params.row.id)}
          >
            Ver mais
          </D.DefaultGridButton>
        </D.GridField>
      ),
    },
  ];

  const columnsModal: GridColDef[] = [
    { field: "id", headerName: "ID", flex: 1 },
    { field: "responsavel", headerName: "Responsavel", flex: 1 },
    { field: "descricao", headerName: "Descrição", flex: 1 },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <D.GridField>
          <D.Circle color={params.value} />
        </D.GridField>
      ),
    },
    { field: "data", headerName: "Data", flex: 1 },
  ];

  const columnsModalDocument: GridColDef[] = [
    { field: "id", headerName: "ID", flex: 1 },
    { field: "descricao", headerName: "Descrição", flex: 1 },
    { field: "data", headerName: "Data Upload", flex: 1 },
    {
      field: "anexo",
      headerName: "Anexo",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <D.GridField>
          <C.ModalGridDownloadButton
            startIcon={<DownloadIcon />}
            variant="outlined"
          >
            Baixar
          </C.ModalGridDownloadButton>
        </D.GridField>
      ),
    },
    {
      field: "remover",
      headerName: "Remover",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <D.GridField>
          <C.ModalGridDeleteButton
            variant="outlined"
            startIcon={<DeleteIcon />}
          >
            Deletar
          </C.ModalGridDeleteButton>
        </D.GridField>
      ),
    },
  ];

  const rowsModalDocument = [
    {
      id: 1,
      descricao: "Conta Cemig",
      data: "05/11/2013",
      anexo: 0,
      remover: 0,
    },
    {
      id: 2,
      descricao: "Documento Pessoal",
      data: "05/11/2013",
      anexo: 0,
      remover: 0,
    },
  ];

  return (
    <>
      {loading ? (
        <SkeletonDefaultPage />
      ) : (
        <C.Container>
          <C.Title onClick={() => handleStatusOpen()}>
            Status
            {isMobile && (
              <>
                {!statusOpen ? (
                  <KeyboardArrowDownRoundedIcon />
                ) : (
                  <KeyboardArrowUpIcon />
                )}
              </>
            )}
          </C.Title>

          {(!isMobile || (statusOpen && isMobile)) && (
            <C.StatusArea>
              <C.StatusWrapper>
                <StatusCard
                  color="yellow"
                  text="Não teve interesse"
                  number={statsDashboard[0].quantidade}
                />
                <TotalCard
                  color="yellow"
                  title="Valor total das propostas"
                  text={`${moneyFormatter.format(
                    statsDashboard[0].totalReais
                  )}`}
                  evolutionNumber="-8%"
                  evolution={false}
                />
                <TotalCard
                  color="yellow"
                  title="Valor total de kwh"
                  text={`${statsDashboard[0].totalkWh} kwh`}
                  evolutionNumber="+8%"
                  evolution={true}
                />
              </C.StatusWrapper>
              <C.StatusWrapper>
                <StatusCard
                  color="blue"
                  text="Em análise"
                  number={statsDashboard[1].quantidade}
                />
                <TotalCard
                  color="blue"
                  title="Valor total das propostas"
                  text={`${moneyFormatter.format(
                    statsDashboard[1].totalReais
                  )}`}
                  evolutionNumber="-8%"
                  evolution={false}
                />
                <TotalCard
                  color="blue"
                  title="Valor total de kwh"
                  text={`${statsDashboard[1].totalkWh} kwh`}
                  evolutionNumber="+8%"
                  evolution={true}
                />
              </C.StatusWrapper>
              <C.StatusWrapper>
                <StatusCard
                  color="green"
                  text="Contrato Gerado"
                  number={statsDashboard[2].quantidade}
                />
                <TotalCard
                  color="green"
                  title="Valor total das propostas"
                  text={`${moneyFormatter.format(
                    statsDashboard[2].totalReais
                  )}`}
                  evolutionNumber="-8%"
                  evolution={false}
                />
                <TotalCard
                  color="green"
                  title="Valor total de kwh"
                  text={`${statsDashboard[2].totalkWh} kwh`}
                  evolutionNumber="+8%"
                  evolution={true}
                />
              </C.StatusWrapper>
              <C.StatusWrapper>
                <StatusCard
                  color="red"
                  text="Reprovado"
                  number={statsDashboard[3].quantidade}
                />
                <TotalCard
                  color="red"
                  title="Valor total das propostas"
                  text={`${moneyFormatter.format(
                    statsDashboard[3].totalReais
                  )}`}
                  evolutionNumber="-8%"
                  evolution={false}
                />
                <TotalCard
                  color="red"
                  title="Valor total de kwh"
                  text={`${statsDashboard[3].totalkWh} kwh`}
                  evolutionNumber="+8%"
                  evolution={true}
                />
              </C.StatusWrapper>
              <C.StatusWrapper>
                <StatusCard
                  color="purple"
                  text="Cancelado"
                  number={statsDashboard[4].quantidade}
                />
                <TotalCard
                  color="purple"
                  title="Valor total das propostas"
                  text={`${moneyFormatter.format(
                    statsDashboard[4].totalReais
                  )}`}
                  evolutionNumber="-8%"
                  evolution={false}
                />
                <TotalCard
                  color="purple"
                  title="Valor total de kwh"
                  text={`${statsDashboard[4].totalkWh} kwh`}
                  evolutionNumber="-8%"
                  evolution={true}
                />
              </C.StatusWrapper>
            </C.StatusArea>
          )}

          <D.DataArea $align="right">
            {!isMobile ? (
              <Datatable
                columns={columns}
                rows={rows}
                pageNumber={page}
                pageSize={pageSize}
                onChangePage={(e) => handleChangePage(e)}
                onChangePageSize={(e) => handleChangePageSize(e)}
                totalPages={totalPages}
              />
            ) : (
              <C.CardDataAreaMobile>
                {rows.map((row) => (
                  <C.CardDataMobile key={row.id}>
                    <C.CardDataLineMobile>
                      <C.CardDataItemMobile>
                        <label>Id:</label>
                        <p>{row.id}</p>
                      </C.CardDataItemMobile>
                      <C.CardDataItemMobile></C.CardDataItemMobile>
                      <C.CardDataItemMobile $center={true}>
                        <label>Status:</label>
                        <D.Circle
                          color={
                            StatusContratoParceiroColorMap[Number(row.status)]
                          }
                        />
                      </C.CardDataItemMobile>
                    </C.CardDataLineMobile>
                    <C.CardDataLineMobile>
                      <C.CardDataItemMobile>
                        <label>Cliente:</label>
                        <p>{row.cliente}</p>
                      </C.CardDataItemMobile>
                    </C.CardDataLineMobile>
                    <C.CardDataLineMobile>
                      <C.CardDataItemMobile>
                        <label>Parceiro:</label>
                        <p>{row.parceiro}</p>
                      </C.CardDataItemMobile>
                      <C.CardDataItemMobile>
                        <label>Instalação:</label>
                        <p>{row.instalacao}</p>
                      </C.CardDataItemMobile>
                    </C.CardDataLineMobile>
                    <C.CardDataLineMobile>
                      <C.CardDataItemMobile $center={true}>
                        <label>Contratar:</label>
                        <D.GridImage src={ContractIcon} alt="contractIcon" />
                      </C.CardDataItemMobile>
                      <C.CardDataItemMobile $center={true}>
                        <label>Documentos:</label>
                        <D.GridImage
                          onClick={() => handleOpenModalDocuments()}
                          src={FolderIcon}
                          alt="folderIcon"
                        />
                      </C.CardDataItemMobile>
                      <C.CardDataItemMobile $center={true}>
                        <label>Propostas:</label>
                        <D.GridImage src={PourposeIcon} alt="pourposeIcon" />
                      </C.CardDataItemMobile>
                      <C.CardDataItemMobile $center={true}>
                        <label></label>
                        <D.DefaultGridButton
                          onClick={() => handleOpenModalSeeMore(row.id)}
                        >
                          Ver mais
                        </D.DefaultGridButton>
                      </C.CardDataItemMobile>
                    </C.CardDataLineMobile>
                  </C.CardDataMobile>
                ))}
                <D.DefaultPagination
                  count={10}
                  size={isMobile ? "small" : "medium"}
                  siblingCount={isMobile ? 0 : 1}
                  showFirstButton
                  showLastButton
                  onChange={handleChangeMobile}
                  color="primary"
                />
              </C.CardDataAreaMobile>
            )}

            <C.LineBreak />
            {/* <C.ReportsArea>
            <C.SelectArea>
              <C.ReportsLabel>Status de propostas</C.ReportsLabel>
              <C.ReportsSelect
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                defaultValue={""}
              >
                <MenuItem value="">
                  <em>Em análise</em>
                </MenuItem>
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </C.ReportsSelect>
            </C.SelectArea>
            <C.SelectArea>
              <C.ReportsLabel>Selecione um parceiro</C.ReportsLabel>
              <C.ReportsSelect
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                defaultValue={""}
              >
                <MenuItem value="">
                  <em>Todos os parceiros</em>
                </MenuItem>
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </C.ReportsSelect>
            </C.SelectArea>
            <C.SelectArea>
              <C.ReportsLabel>Selecione o backoffice</C.ReportsLabel>
              <C.ReportsSelect
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                defaultValue={""}
              >
                <MenuItem value="">
                  <em>Todos os backoffice</em>
                </MenuItem>
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </C.ReportsSelect>
            </C.SelectArea>
            <C.SelectArea>
              <C.ReportsLabel>Data da ultima movimentação</C.ReportsLabel>
              <TextField
                fullWidth
                type="date"
                inputProps={{ "aria-label": "Without label" }}
              ></TextField>
            </C.SelectArea>
          </C.ReportsArea>
          <C.ExportButton
            variant="contained"
            startIcon={
              <img
                style={{ width: "20px", color: "white", fill: "white" }}
                src={ExcelIcon}
                alt="excelIcon"
              />
            }
          >
            Gerar relatório
          </C.ExportButton> */}
          </D.DataArea>
        </C.Container>
      )}
      <Modal
        sx={{ width: "100%", zIndex: 10 }}
        open={openModalSeeMore}
        onClose={handleCloseModalSeeMore}
        aria-labelledby="modal-details"
        aria-describedby="modal-details"
      >
        <C.ModalContainer>
          <C.ModalArea>
            <C.ModalCard>
              <C.ModalHeader>
                <h2>
                  {GDProposta.cliente} - {GDProposta.idReferencial}
                </h2>
                <C.CloseButton
                  aria-label="close"
                  onClick={handleCloseModalSeeMore}
                >
                  <CloseIcon />
                </C.CloseButton>
              </C.ModalHeader>
              <h4>Status atual:</h4>
              <C.ModalStatusArea>
                <CircleCheckbox
                  id={
                    EnumStatusGDPropostaNumericMap[
                      EnumStatusGDProposta.naoTeveInteresse
                    ]
                  }
                  color="yellow"
                  label="Não teve interesse"
                  selected={
                    selectedId ===
                    EnumStatusGDPropostaNumericMap[
                      EnumStatusGDProposta.naoTeveInteresse
                    ]
                  }
                  onSelect={handleSelect}
                />
                <CircleCheckbox
                  id={
                    EnumStatusGDPropostaNumericMap[
                      EnumStatusGDProposta.emAnalise
                    ]
                  }
                  color="blue"
                  label="Em análise"
                  selected={
                    selectedId ===
                    EnumStatusGDPropostaNumericMap[
                      EnumStatusGDProposta.emAnalise
                    ]
                  }
                  onSelect={handleSelect}
                />
                <CircleCheckbox
                  id={
                    EnumStatusGDPropostaNumericMap[
                      EnumStatusGDProposta.contratoGerado
                    ]
                  }
                  color="green"
                  label="Contrato Gerado"
                  selected={
                    selectedId ===
                    EnumStatusGDPropostaNumericMap[
                      EnumStatusGDProposta.contratoGerado
                    ]
                  }
                  onSelect={handleSelect}
                />
                <CircleCheckbox
                  id={
                    EnumStatusGDPropostaNumericMap[
                      EnumStatusGDProposta.reprovada
                    ]
                  }
                  color="red"
                  label="Reprovado"
                  selected={
                    selectedId ===
                    EnumStatusGDPropostaNumericMap[
                      EnumStatusGDProposta.reprovada
                    ]
                  }
                  onSelect={handleSelect}
                />
                <CircleCheckbox
                  id={
                    EnumStatusGDPropostaNumericMap[
                      EnumStatusGDProposta.cancelada
                    ]
                  }
                  color="purple"
                  label="Cancelado"
                  selected={
                    selectedId ===
                    EnumStatusGDPropostaNumericMap[
                      EnumStatusGDProposta.cancelada
                    ]
                  }
                  onSelect={handleSelect}
                />
              </C.ModalStatusArea>
              <C.ModalInputsArea>
                <C.ModalInput
                  label="Nome"
                  value={nomeGDProposta}
                  onChange={(e) => {
                    setNomeGDProposta(e.target.value);
                  }}
                  inputProps={{ readOnly: !editar }}
                />
                <C.ModalInput
                  label="Celular"
                  value={celularGDProposta}
                  onChange={(e) => {
                    setCelularGDProposta(e.target.value);
                  }}
                  inputProps={{ readOnly: !editar }}
                />
                <C.ModalInput
                  label="Email"
                  value={emailGDProposta}
                  onChange={(e) => {
                    setEmailGDProposta(e.target.value);
                  }}
                  inputProps={{ readOnly: !editar }}
                />
                <C.ModalInput
                  label="Parceiro"
                  value={parceiroGDProposta}
                  onChange={(e) => {
                    setParceiroGDProposta(e.target.value);
                  }}
                  inputProps={{ readOnly: true }}
                />
                <C.ModalInput
                  label="Backoffice"
                  value={backofficeGDProposta}
                  onChange={(e) => {
                    setBackofficeGDProposta(e.target.value);
                  }}
                  inputProps={{ readOnly: true }}
                />
                <C.ModalInput
                  label="Data"
                  value={createdAtGDProposta}
                  onChange={(e) => {
                    setCreatedAtGDProposta(
                      new Date(e.target.value).toLocaleDateString()
                    );
                  }}
                  inputProps={{ readOnly: true }}
                />
              </C.ModalInputsArea>
              <C.ModalButtonsArea>
                <C.ModalSaveButton
                  variant="contained"
                  disabled={editar}
                  onClick={() => setEditar(true)}
                >
                  Editar
                </C.ModalSaveButton>
                <C.ModalSaveButton
                  variant="contained"
                  disabled={!editar}
                  onClick={() => handleUpdateGDProposta()}
                >
                  Salvar
                </C.ModalSaveButton>
              </C.ModalButtonsArea>
            </C.ModalCard>
          </C.ModalArea>
        </C.ModalContainer>
      </Modal>
      <Modal
        sx={{ width: "100%", zIndex: 10 }}
        open={openModalDocuments}
        onClose={handleCloseModalDocuments}
        aria-labelledby="modal-details"
        aria-describedby="modal-details"
      >
        <C.ModalContainer>
          <C.ModalArea>
            <C.ModalCard>
              <C.ModalHeader>
                <h2>Proposta - 9591</h2>
                <C.CloseButton
                  aria-label="close"
                  onClick={handleCloseModalDocuments}
                >
                  <CloseIcon />
                </C.CloseButton>
              </C.ModalHeader>
              <C.ModalLine>
                <C.ModalItem>
                  <label>Cliente:</label>
                  <p>João Carlos Cunha Melo</p>
                </C.ModalItem>
                <C.ModalItem>
                  <label>Celular:</label>
                  <p>(31) 974357087</p>
                </C.ModalItem>
                <C.ModalItem>
                  <label>Status atual:</label>
                  <C.ModalTextIcon>
                    {/* <D.Circle color={"green"} /> */}
                    <p>Contrato assinado</p>
                  </C.ModalTextIcon>
                </C.ModalItem>
              </C.ModalLine>
              <C.ModalLine>
                <C.ModalItem>
                  <label>Captador:</label>
                  <p>GCM energia solar</p>
                </C.ModalItem>
                <C.ModalItem>
                  <label>Telefone:</label>
                  <p>(31) 974357087</p>
                </C.ModalItem>
                <C.ModalItem></C.ModalItem>
              </C.ModalLine>
            </C.ModalCard>
            <C.ModalCard>
              <C.ModalHeader>
                <h2>
                  <PublishIcon /> Upload de Arquivos
                </h2>
              </C.ModalHeader>
              <TextField
                label="Descrição do documento da proposta:"
                fullWidth
                multiline
                rows={5}
              />
              <C.ModalButtonsArea>
                <C.ModalDocumentButton
                  variant="contained"
                  startIcon={<PublishIcon />}
                >
                  Anexar Documento
                </C.ModalDocumentButton>
                <C.ModalSaveButton variant="contained">
                  Anexar
                </C.ModalSaveButton>
              </C.ModalButtonsArea>
            </C.ModalCard>
            <C.ModalCard>
              <C.ModalHeader>
                <h2>Download anexo proposta</h2>
              </C.ModalHeader>
              <D.DefaultTable
                disableColumnResize
                disableRowSelectionOnClick
                localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                rows={rowsModalDocument}
                columns={columnsModalDocument}
                initialState={{
                  pagination: {
                    paginationModel: {
                      page: 0,
                      pageSize: rowsModalDocument.length,
                    },
                  },
                }}
                hideFooter
                disableColumnFilter
                disableColumnSelector
                disableColumnMenu
              />
            </C.ModalCard>
          </C.ModalArea>
        </C.ModalContainer>
      </Modal>
    </>
  );
};

export default ParceiroPropostas;
