import React, { useEffect, useReducer, useState } from "react";
import { useAuth } from "../../../../hooks/useAuth";
import * as C from "../../../public/Home/components/Navbar/style";
import logo from "../../../../assets/logo.png";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  tableCellClasses,
  Container,
  Paper,
} from "@mui/material";
import { GetPropostaWattwise } from "../../../../services/api/PropostaService";
import { AxiosResponse } from "axios";
import { IGDProposta } from "../../../../models/GDProposta";
import { useNavigate, useParams } from "react-router-dom";

export enum dataTela {
  ID = "ID",
  IDREFERENCIAL = "IDREFERENCIAL",
  NOME = "NOME",
  CELULARCONTATO = "CELULARCONTATO",
  EMAIL = "EMAIL",
  NINSTALACAO = "NINSTALACAO",
  CONTADISTRIBUIDORA = "CONTADISTRIBUIDORA",
  TARIFAVIGENTE = "TARIFAVIGENTE",
  ILUMINACAOPUBLICA = "ILUMINACAOPUBLICA",
  ULTIMOCONSUMOKWHCONSIDERADO = "ULTIMOCONSUMOKWHCONSIDERADO",
  TAXADISPONIBILIDADE = "TAXADISPONIBILIDADE",
  KHWLIBERADORINJECAOENERGIA = "KHWLIBERADORINJECAOENERGIA",
  VALORATUALDISTRIBUIDORA = "VALORATUALDISTRIBUIDORA",
  CONTADISTRIBUIDORAAPOSCONTATACAO = "CONTADISTRIBUIDORAAPOSCONTATACAO",
  BOLETO = "BOLETO",
  CONTADISTRIBUIDORAMAISBOLETO = "CONTADISTRIBUIDORAMAISBOLETO",
  ECONOMIAMENSAL = "ECONOMIAMENSAL",
  ECONOMIAANUAL = "ECONOMIAANUAL",
  PERCENTUALDEDESCONTOSOBRETARIFA = "PERCENTUALDEDESCONTOSOBRETARIFA",
}

export interface IdataTela {
  id: "";
  idreferencial: 0;
  nome: "";
  celularcontato: "";
  email: "";
  ninstalacao: "";
  contadistribuidora: 0;
  tarifavigente: 0;
  iluminacaopublica: "";
  ultimoconsumokwhconsiderado: "";
  taxadisponibilidade: "";
  khwliberadorinjecaoenergia: 0;
  valoratualdistribuidora: 0;
  contadistribuidoraaposcontatacao: 0;
  boleto: 0;
  contadistribuidoramaisboleto: 0;
  economiamensal: 0;
  economiaanual: 0;
  percentualdedescontosobretarifa: 0;
}

export const PropostaWattwise = () => {
  const { idProposta } = useParams();
  const navigate = useNavigate();
  const setDataForm = (
    state: IdataTela,
    action: { type: dataTela; payload: any }
  ) => {
    const { type, payload } = action;

    if (state.hasOwnProperty(type.toLowerCase())) {
      return { ...state, [type.toLowerCase()]: payload };
    }

    return state;
  };

  const [state, dispatch] = useReducer(setDataForm, {
    id: "",
    idreferencial: 0,
    nome: "",
    celularcontato: "",
    email: "",
    ninstalacao: "",
    contadistribuidora: 0,
    tarifavigente: 0,
    iluminacaopublica: "",
    ultimoconsumokwhconsiderado: "",
    taxadisponibilidade: "",
    khwliberadorinjecaoenergia: 0,
    valoratualdistribuidora: 0,
    contadistribuidoraaposcontatacao: 0,
    boleto: 0,
    contadistribuidoramaisboleto: 0,
    economiamensal: 0,
    economiaanual: 0,
    percentualdedescontosobretarifa: 0,
  });

  const rowsSituacaoAtual: string[] = [
    "Conta distribuidora",
    "Tarifa vigente:",
    "Iluminação pública:",
    "Último consumo kwh considerado",
    "Taxa de disponibilidade",
    "KWH liberado para injeção de energia",
    "Valor atual da distribuidora (Energia consumida + iluminação pública)",
  ];
  const rowsContaDistribuidoraEstimadaAposContratação: string[] = [
    "Conta distribuidora após a contratação",
  ];
  const rowsEnergiaPorAssinatura: string[] = [
    "Boleto (contribuição mensal mediante a energia injetada em sua conta)",
  ];
  const rowsDespesasTotais: string[] = ["Conta distribuidora + boleto"];
  const { user } = useAuth();

  useEffect(() => {
    document.body.style.backgroundColor = "#6c757d";
    document.body.style.overflowX = "hidden";

    getDadosUsuario(idProposta!);

    return () => {
      document.body.style.backgroundColor = "";
      document.body.style.overflowX = "";
    };
  }, []);

  const getDadosUsuario = async (id: string | null): Promise<void> => {
    try {
      const resposta: AxiosResponse<IGDProposta> = await GetPropostaWattwise(
        id
      );
      const data: any = resposta.data;

      dispatch({
        type: dataTela.ID,
        payload:
          "" +
          data.idReferencial.toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
          }),
      });
      dispatch({
        type: dataTela.NOME,
        payload:
          "" +
          data.nome.toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
          }),
      });
      dispatch({
        type: dataTela.CELULARCONTATO,
        payload:
          "" +
          data.celularContato.toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
          }),
      });
      dispatch({
        type: dataTela.EMAIL,
        payload:
          "" +
          data.email.toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
          }),
      });
      dispatch({
        type: dataTela.NINSTALACAO,
        payload:
          "" +
          data.nInstalacao.toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
          }),
      });
      dispatch({
        type: dataTela.CONTADISTRIBUIDORA,
        payload:
          "" +
          data.contaDistribuidora.toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
          }),
      });
      dispatch({
        type: dataTela.TARIFAVIGENTE,
        payload:
          "" +
          data.tarifaVigente.toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
          }),
      });
      dispatch({
        type: dataTela.ILUMINACAOPUBLICA,
        payload: parseFloat(data.iluminacaoPublica).toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        }),
      });
      dispatch({
        type: dataTela.ULTIMOCONSUMOKWHCONSIDERADO,
        payload: "" + data.ultimoConsumoKWHConsiderado + " KWH",
      });
      dispatch({
        type: dataTela.TAXADISPONIBILIDADE,
        payload: "" + data.taxaDisponibilidade + " KWH",
      });
      dispatch({
        type: dataTela.KHWLIBERADORINJECAOENERGIA,
        payload: "" + data.khwLiberadorInjecaoEnergia + " KWH",
      });
      dispatch({
        type: dataTela.VALORATUALDISTRIBUIDORA,
        payload:
          "" +
          data.valorAtualDistribuidora.toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
          }),
      });
      dispatch({
        type: dataTela.CONTADISTRIBUIDORAAPOSCONTATACAO,
        payload:
          "" +
          data.contaDistribuidoraAposContatacao.toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
          }),
      });
      dispatch({
        type: dataTela.BOLETO,
        payload:
          "" +
          data.boleto.toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
          }),
      });
      dispatch({
        type: dataTela.CONTADISTRIBUIDORAMAISBOLETO,
        payload:
          "" +
          data.contaDistribuidoraMaisBoleto.toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
          }),
      });
      dispatch({
        type: dataTela.ECONOMIAMENSAL,
        payload:
          "" +
          data.economiaMensal.toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
          }),
      });
      dispatch({
        type: dataTela.ECONOMIAANUAL,
        payload:
          "" +
          data.economiaAnual.toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
          }),
      });
      dispatch({
        type: dataTela.PERCENTUALDEDESCONTOSOBRETARIFA,
        payload: "" + data.percentualDeDescontoSobreTarifa + " %",
      });
    } catch (e: unknown) {
      navigate(-1);
    }
  };

  return (
    <Box
      style={{
        width: "100%",
      }}
    >
      <Paper
        sx={{
          backgroundColor: "#fff",
          display: "flex",
          flexDirection: "column",
          padding: "40px",
          marginLeft: 20,
          marginRight: 20,
          marginTop: 3,
          gap: 3,
        }}
      >
        <Box>
          <C.Logo
            src={logo}
            alt="logoWattwise"
            style={{
              width: "175px",
              height: "86px",
            }}
          />
        </Box>
        <Box>
          <Typography
            style={{
              width: "174px",
              height: "14px",
              opacity: "0px",
              whiteSpace: "nowrap",
              color: "#D14192",
              fontWeight: 800,
            }}
          >
            PROPOSTA WATTWISE - ID:
          </Typography>
        </Box>
        <Box>
          <Typography
            style={{
              whiteSpace: "nowrap",
            }}
          >
            <b>Nome do cliente: {state.nome}</b>
          </Typography>
          <Typography
            style={{
              whiteSpace: "nowrap",
            }}
          >
            <b>Celular de contato: {state.celularcontato}</b>
          </Typography>
          <Typography
            style={{
              whiteSpace: "nowrap",
            }}
          >
            <b>E-mail: {state.email}</b>
          </Typography>
          <Typography
            style={{
              whiteSpace: "nowrap",
            }}
          >
            <b>Nº de instalação: {state.ninstalacao}</b>
          </Typography>
        </Box>
        <Box sx={{ backgroundColor: "#F8F8F8" }}>
          <TableContainer>
            <Table
              aria-label="caption table"
              sx={{
                marginBottom: 3,
                [`& .${tableCellClasses.root}`]: {
                  borderBottom: "none",
                },
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{ color: "#D14192", paddingTop: 0, paddingBottom: 0 }}
                  >
                    <h3>Situação atual</h3>
                  </TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rowsSituacaoAtual.map(
                  (
                    dados: string,
                    index: number | undefined
                  ): React.JSX.Element => {
                    let valor: string | number;

                    switch (index) {
                      case 0:
                        valor = state.contadistribuidora;
                        break;
                      case 1:
                        valor = state.tarifavigente;
                        break;
                      case 2:
                        valor = state.iluminacaopublica;
                        break;
                      case 3:
                        valor = state.ultimoconsumokwhconsiderado;
                        break;
                      case 4:
                        valor = state.taxadisponibilidade;
                        break;
                      case 5:
                        valor = state.khwliberadorinjecaoenergia;
                        break;
                      case 6:
                        valor = state.valoratualdistribuidora;
                        break;
                      default:
                        valor = "0,00";
                    }

                    return (
                      <TableRow key={index}>
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{ width: "80%", paddingTop: 0, paddingBottom: 0 }}
                        >
                          {dados}
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{
                            borderLeft: "#D14192 2px solid",
                            color: index === 6 ? "red" : "inherit",
                          }}
                        >
                          {valor}
                        </TableCell>
                      </TableRow>
                    );
                  }
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Box sx={{ backgroundColor: "#F8F8F8" }}>
          <TableContainer>
            <Table
              aria-label="caption table"
              sx={{
                [`& .${tableCellClasses.root}`]: {
                  borderBottom: "none",
                },
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{ color: "#D14192", paddingTop: 0, paddingBottom: 0 }}
                  >
                    <h3>Conta distribuidora estimada após contratação</h3>
                  </TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rowsContaDistribuidoraEstimadaAposContratação.map(
                  (
                    dados: string,
                    index: number | undefined
                  ): React.JSX.Element => {
                    let valor: string | number;

                    switch (index) {
                      case 0:
                        valor = state.contadistribuidoraaposcontatacao;
                        break;
                      default:
                        valor = "0,00";
                    }

                    return (
                      <TableRow key={index}>
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{ width: "80%", paddingTop: 0, paddingBottom: 0 }}
                        >
                          {dados}
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{ borderLeft: "#D14192 2px solid" }}
                        >
                          {valor}
                        </TableCell>
                      </TableRow>
                    );
                  }
                )}
              </TableBody>
            </Table>

            <Table
              aria-label="caption table"
              sx={{
                marginBottom: 3,
                [`& .${tableCellClasses.root}`]: {
                  borderBottom: "none",
                },
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{ color: "#D14192", paddingTop: 0, paddingBottom: 0 }}
                  >
                    <h3>Energia por assinatura</h3>
                  </TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rowsEnergiaPorAssinatura.map(
                  (
                    dados: string,
                    index: number | undefined
                  ): React.JSX.Element => {
                    let valor: string | number;

                    switch (index) {
                      case 0:
                        valor = state.boleto;
                        break;
                      default:
                        valor = "0,00";
                    }

                    return (
                      <TableRow key={index}>
                        <TableCell
                          component="th"
                          sx={{ width: "80%", paddingTop: 0, paddingBottom: 0 }}
                          scope="row"
                        >
                          {dados}
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{ borderLeft: "#D14192 2px solid" }}
                        >
                          {valor}
                        </TableCell>
                      </TableRow>
                    );
                  }
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Box sx={{ backgroundColor: "#F8F8F8" }}>
          <TableContainer>
            <Table
              aria-label="caption table"
              sx={{
                marginBottom: 3,
                [`& .${tableCellClasses.root}`]: {
                  borderBottom: "none",
                },
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{ color: "#D14192", paddingTop: 0, paddingBottom: 0 }}
                  >
                    <h3>Despesas totais</h3>
                  </TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rowsDespesasTotais.map(
                  (
                    dados: string,
                    index: number | undefined
                  ): React.JSX.Element => {
                    let valor: string | number;

                    switch (index) {
                      case 0:
                        valor = state.contadistribuidoramaisboleto;
                        break;
                      default:
                        valor = "0,00";
                    }

                    return (
                      <TableRow key={index}>
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{ width: "80%", paddingTop: 0, paddingBottom: 0 }}
                        >
                          {dados}
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{
                            borderLeft: "#D14192 2px solid",
                            color: "#28a745",
                          }}
                        >
                          {" "}
                          {valor}
                        </TableCell>
                      </TableRow>
                    );
                  }
                )}
                <TableRow>
                  <TableCell
                    component="th"
                    sx={{
                      width: "80%",
                      color: "#D14192",
                      paddingTop: 0,
                      paddingBottom: 0,
                    }}
                    scope="row"
                  >
                    <h3>Economia mensal</h3>
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{
                      borderLeft: "#D14192 2px solid",
                      color: "#28a745",
                    }}
                  >
                    {" "}
                    {state.economiamensal}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    component="th"
                    sx={{
                      width: "80%",
                      color: "#D14192",
                      paddingTop: 0,
                      paddingBottom: 0,
                    }}
                    scope="row"
                  >
                    <h3>Economia anual</h3>
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{ borderLeft: "#D14192 2px solid", color: "#28a745" }}
                  >
                    {" "}
                    {state.economiaanual}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    component="th"
                    sx={{
                      width: "80%",
                      color: "#D14192",
                      paddingTop: 0,
                      paddingBottom: 0,
                    }}
                    scope="row"
                  >
                    <h3>Percentual de desconto sobre a tarifa</h3>
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{
                      borderLeft: "#D14192 2px solid",
                      color: "#28a745",
                    }}
                  >
                    {" "}
                    {state.percentualdedescontosobretarifa}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Paper>
    </Box>
  );
};
